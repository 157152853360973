/* Importing Bootstrap SCSS file. */
//@import '~bootstrap/scss/bootstrap';

/* You can add global styles to this file, and also import other style files */
.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

/* styles.css */
.modal-backdrop {
  z-index: 1050 !important; /* You can adjust this value based on your needs */
}
@keyframes example {
  0% {
    transform: scale(0.5)
  }

  100% {
    transform: scale(1)
  }
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}
