// 
// _card.scss
// 

.card {
  margin-bottom: $grid-gutter-width;
  box-shadow: $box-shadow;
  border:1px solid #F1F1F4;border-radius:0.625rem
}

.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 20px;color:#071437;
  margin: 0 0 7px 0;
  font-weight: $font-weight-semibold;
}

.card-title-desc {
  color: $card-title-desc;
  margin-bottom: 24px;  
}

